import 'twin.macro';

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { GraphQLClient, gql } from 'graphql-request';
import { saveAs } from 'file-saver';

import config from '../utils/config';
import Layout from '../components/Layout';
import Seo from '../components/global/Seo';
import { Heading, Container } from '../components/atoms';
import SanityImage from '../components/global/SanityImage';
import BrochureForm from '../components/brochure/BrochureForm';
import ContentBlocks from '../sanity/ContentBlocks';

const client = new GraphQLClient(config.apiUrl, {
  timeout: 60000,
});

const contactUsMutation = gql`
  mutation contact($input: ContactInput) {
    contact(input: $input) {
      status
      message
    }
  }
`;

// import CollectionView from '../components/collection/CollectionView';

const BrochurePage = ({ data: data2 }) => {
  const { page } = data2;

  const handleSubmit = async (formData) => {
    const variables = { input: formData };

    await client
      .request(contactUsMutation, variables)
      .then((data) => {
        setTimeout(() => {
          if (data) {
            saveAs(
              page?.brochureFile?.asset?.url,
              page?.brochureFile?.asset?.filename,
            );
          }
        }, 3000);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <Layout>
      <Seo
        title={page.title}
        description={page.description}
        image={page.image && page.image.asset && page.image.asset.url}
      />

      <div>
        <Container width="full" tw="flex items-center md:mt-10">
          <div tw="container mx-auto flex flex-wrap items-center">
            <div tw="w-full md:w-7/12 text-center p-4 -mt-20">
              <SanityImage fluid={page.image?.asset?.gatsbyImageData} />
            </div>
            <div tw="w-full md:w-5/12 text-center md:text-left p-4">
              <div tw="sm:text-center lg:text-left">
                <Heading tw="text-black">{page.title}</Heading>
                <BrochureForm onSubmit={(val) => handleSubmit(val)} />
              </div>
            </div>
          </div>
        </Container>
      </div>

      {page.contentBlocks &&
        page.contentBlocks.map((block, idx) =>
          React.createElement(ContentBlocks(block._type), {
            idx,
            key: block._key,
            ...block,
          }),
        )}
    </Layout>
  );
};

BrochurePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BrochurePage;

export const query = graphql`
  query ($slug: String!) {
    page: sanityBrochures(slug: { current: { eq: $slug } }) {
      _id
      title
      slug {
        current
      }
      description
      image {
        asset {
          gatsbyImageData
          url
        }
      }
      brochureFile {
        asset {
          url
        }
      }
      contentBlocks: _rawContentBlocks(resolveReferences: { maxDepth: 10 })
    }
  }
`;
